<template>
  <div>
    <v-img height="300" :src="images[0]">
      <v-container class="fill-height white--text display-1 justify-center">
        <div>Gallery</div>
      </v-container>
    </v-img>

    <v-container style="margin-top: -110px">
      <v-row>
        <v-col>
          <template v-if="images != 0">
            <v-row class="fill-height">
              <v-col cols="12" md="4" v-for="(image, i) in images" :key="i">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? '18':'6'"
                    height="100%"
                    @click.stop="activeDialog(image)"
                  >
                    <v-img height="100%" width="100%" :src="image">
                      <v-btn
                        v-if="hover && currentUserRole === 'admin'"
                        @click="deleteImage(image,i)"
                        icon
                      >
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" md="4" v-if="currentUserRole === 'admin'" class="pa-2" sm="3">
                <v-sheet color="purple  lighten-2" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-btn @click="dialogAdd = true" dark text>Add New</v-btn>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12">
                <v-card height="200" color="f5f5f5">
                  <v-card-text>Nothing to show</v-card-text>
                  <v-col v-if="currentUserRole === 'admin'" class="pa-2" cols="12">
                    <v-btn color="primary lighten-3" large @click="dialogAdd = true">Add New</v-btn>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-dialog full-width v-model="dialogAdd" max-width="720">
            <v-card align="center">
              <v-card-title>Upload Logo</v-card-title>
              <v-card-text>
                <v-file-input v-if="progress === 0" v-model="newImage"></v-file-input>
                <v-progress-circular
                  v-else
                  size="56"
                  color="primary"
                  v-model="progress"
                >{{ parseInt(progress) }}</v-progress-circular>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="green darken-1" text @click="dialogAdd = false">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="uploadImage()">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
          <v-dialog full-width v-model="dialog" max-width="720">
            <v-card>
              <v-card-actions class="justify-end">
                <v-btn icon right color="primary" text @click="dialog = false">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <v-img max-height="500" :src="dialogImage"></v-img>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <Snackbar :snackbar="snackbar" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialogAdd: false,
      dialog: false,
      dialogImage: "",
      progress: 0,
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      images: null,
      newImage: []
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "gallery"])
  },
  methods: {
    ...mapActions(["bind", "update"]),
    fetchGallery() {
      if (Object.keys(this.gallery).length === 0) {
        this.bind({
          collection: "pages",
          doc: "gallery",
          data: "gallery"
        }).then(() => {
          this.images = this.gallery.images;
        });
      } else {
        this.images = this.gallery.images;
      }
    },

    activeDialog(image) {
      this.dialogImage = image;
      this.dialog = true;
    },
    // uploadImage,
    uploadImage() {
      if (this.newImage) {
        // console.log(image.target.files[0])
        let file = this.newImage;
        let time = Date.now();
        // File or Blob named mountains.jpg
        let storageRef = firebase
          .storage()
          .ref("gallery/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // console.log("Upload is " + this.progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.progress = 0;
              this.newImage = [];
              this.images.push(downloadURL);
              this.update({
                collection: "pages",
                doc: "gallery",
                data: {
                  images: this.images
                }
              });
              this.snackbar.text = "Uploaded successfully";
              this.snackbar.color = "success";
              this.snackbar.isActive = true;
              this.dialogAdd = false;
            });
          }
        );
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    },
    // Delete image function
    deleteImage(imageUrl, index) {
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // console.log("File deleted successfully");
          this.images.splice(index, 1); //Remove from Data: newProduct.images[]
          this.update({
            collection: "pages",
            doc: "gallery",
            data: {
              images: this.images
            }
          });
          this.snackbar.text = "Image deleted successfully";
          this.snackbar.color = "success";
          this.snackbar.isActive = true;
        })
        .catch(error => {
          this.snackbar.text = "Uh-oh, an error occurred!";
          this.snackbar.color = "error";
          this.snackbar.isActive = true;
          // console.log("Uh-oh, an error occurred!");
        });
    }
  },
  created() {
    this.fetchGallery();
  }
};
</script>

<style lang="scss" scoped></style>
